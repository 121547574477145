import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'reducers';
import Home from 'components/Home';
import Search from 'components/Search';
import Timeline from 'components/Timeline';
import Player from 'components/Shared/Player';
import External from 'components/Shared/External';

const store = createStore(rootReducer, applyMiddleware(thunk));

const App = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/timeline" component={Timeline} />
        <Route path="/search" component={Search} />
      </Switch>
    </Router>
    <Player />
    <External />
  </Provider>
);

export default App;
