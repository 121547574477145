import { LookupTypes } from './types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ILookupState } from 'reducers/lookups.reducer';

export interface IGetGenresAction {
    type: LookupTypes.GET_GENRES,
    data: any[]
}

export function getGenres(): ThunkAction<
Promise<IGetGenresAction>,
ILookupState,
undefined,
IGetGenresAction>
{
    return async (dispatch: ThunkDispatch<ILookupState, undefined, IGetGenresAction>) => {
        const res = await fetch(
          process.env.REACT_APP_BASE_URL + '/api/Lookup/LoadGenres'
        );
        const genres = await res.json();
    
        return dispatch({
          type: LookupTypes.GET_GENRES,
          data: genres
        });
      };
}