import { SubscriptionTypes } from './types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ISubscriptionState } from 'reducers/subscription.reducer';

export interface ISubscribeAction {
  type: SubscriptionTypes.SUBSCRIBE,
  data: any
}

export interface IFetchSubscribeAction {
  type: SubscriptionTypes.FETCH_SUBSCRIPTIONS,
  data: any[]
}

export interface IFetchEpisodesAction {
  type: SubscriptionTypes.FETCH_EPISODES,
  data: any
}

export interface IFetchTimelineAction {
  type: SubscriptionTypes.FETCH_TIMELINE,
  data: any
}

export interface IMarkAsPlayedAction {
  type: SubscriptionTypes.MARK_AS_PLAYED
}

export interface ITrackPlayingAction {
  type: SubscriptionTypes.TRACK_PLAYING
}

export interface IPlayEventAction {
  type: SubscriptionTypes.PLAY_EVENT,
  data: any
}

export function subscribe(podcast: any, userId: any, access_token: any): ThunkAction<
  Promise<ISubscribeAction>,
  ISubscriptionState,
  undefined,
  ISubscribeAction> {
  return async (dispatch: ThunkDispatch<ISubscriptionState, undefined, ISubscribeAction>) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
      },
      body: JSON.stringify(podcast)
    };

    const res = await fetch(
      process.env.REACT_APP_BASE_URL + '/api/Subscription?userId=' + userId, requestOptions);
    const result = await res.json();

    return dispatch({
      type: SubscriptionTypes.SUBSCRIBE,
      data: result
    });
  };
}

export function fetchSubscribe(userId: any, access_token: any): ThunkAction<
  Promise<IFetchSubscribeAction>,
  ISubscriptionState,
  undefined,
  IFetchSubscribeAction> {
  return async (dispatch: ThunkDispatch<IFetchEpisodesAction, undefined, IFetchSubscribeAction>) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        }
    };
    const res = await fetch(process.env.REACT_APP_BASE_URL + '/api/Subscription?userId=' + userId, requestOptions);
    const subscriptions = await res.json();

    return dispatch({
      type: SubscriptionTypes.FETCH_SUBSCRIPTIONS,
      data: subscriptions
    });
  };
}

export function fetchEpisodes(collectionId: number, userId: any, access_token: any): ThunkAction<
  Promise<IFetchEpisodesAction>,
  ISubscriptionState,
  undefined,
  IFetchEpisodesAction> {
  return async (dispatch: ThunkDispatch<ISubscriptionState, undefined, IFetchEpisodesAction>) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        }
    };
    const res = await fetch(process.env.REACT_APP_BASE_URL + '/api/UpdateEpisodes?collectionId=' + collectionId + '&userId=' + userId, requestOptions);
    const result = await res.json();

    if (!result.successful) {
      console.log("Update episodes unsuccessful!: " + collectionId)
    }

    return dispatch({
      type: SubscriptionTypes.FETCH_EPISODES,
      data: { episodes: result.returnedItems, collectionId: collectionId }
    });
  };
}

export function fetchTimeline(userId: any, access_token :any, includeFinished: boolean, from: number, take: number): ThunkAction<
  Promise<IFetchTimelineAction>,
  ISubscriptionState,
  undefined,
  IFetchTimelineAction> {
  return async (dispatch: ThunkDispatch<ISubscriptionState, undefined, IFetchTimelineAction>) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        }
    };
    const res = await fetch(
      process.env.REACT_APP_BASE_URL + '/api/timeline?userId=' + userId + "&includeFinished=" + includeFinished + "&from=" + from + "&take=" + take, requestOptions);
    const result = await res.json();

    if (!result.successful) {
      console.log("Update fetchTimeline unsuccessful!: ");
    }

    return dispatch({
      type: SubscriptionTypes.FETCH_TIMELINE,
      data: { episodes: result }
    });
  };
}

export function markAsPlayed(userId: any, access_token: any, subscriptionId: number, trackId: number): ThunkAction<
  Promise<IMarkAsPlayedAction>,
  ISubscriptionState,
  undefined,
  IMarkAsPlayedAction> {
  return async (dispatch: ThunkDispatch<ISubscriptionState, undefined, IMarkAsPlayedAction>) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        }
    };
    await fetch(process.env.REACT_APP_BASE_URL + '/api/trackfinished?id=' + subscriptionId + '&userId=' + userId + '&trackId=' + trackId, requestOptions);

    return dispatch({
      type: SubscriptionTypes.MARK_AS_PLAYED
    });
  };
}

export function trackPlaying(url: any, position: any, duration: any, userId: any, access_token: any): ThunkAction<
  Promise<ITrackPlayingAction>,
  ISubscriptionState,
  undefined,
  ITrackPlayingAction> {
  return async (dispatch: ThunkDispatch<ISubscriptionState, undefined, ITrackPlayingAction>) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token
        }
    };
    await fetch(process.env.REACT_APP_BASE_URL + '/api/CurrentPosition?url=' + url + '&position=' + position + '&userId=' + userId + '&duration=' + duration, requestOptions);

    return dispatch({
      type: SubscriptionTypes.TRACK_PLAYING
    });
  };
}

export function playEvent(isPlaying: any, url: any): IPlayEventAction {
  return {
    type: SubscriptionTypes.PLAY_EVENT,
    data: { isPlaying: isPlaying, url: url }
  };
}