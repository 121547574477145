import { SearchTypes } from './types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ISearchState } from 'reducers/search.reducer';

export interface ISearchByGenresAction {
    type: SearchTypes.SEARCH_BY_GENRE,
    data: any[]
}

export interface ISearchByTextAction {
    type: SearchTypes.SEARCH_BY_TEXT,
    data: any[]
}

export interface IShowSearchingAction {
    type: SearchTypes.SHOW_SEARCH
}


export function showSearch(): IShowSearchingAction{
  return {
      type: SearchTypes.SHOW_SEARCH
  };
}

export function searchByGenre(genreId: number, access_token: any): ThunkAction<
Promise<ISearchByGenresAction>,
ISearchState,
undefined,
ISearchByGenresAction>
{
    return async (dispatch: ThunkDispatch<ISearchState, undefined, ISearchByGenresAction>) => {
      const requestOptions = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + access_token
          }
      };
        const res = await fetch(
          process.env.REACT_APP_BASE_URL + '/api/PodcastSearch/SearchByGenre?genreId=' + genreId,
          requestOptions
        );
        const podcasts = await res.json();
    
        return dispatch({
          type: SearchTypes.SEARCH_BY_GENRE,
          data: podcasts
        });
      };
}

export function searchByText(text: string, access_token: any): ThunkAction<
Promise<ISearchByTextAction>,
ISearchState,
undefined,
ISearchByTextAction>
{
    return async (dispatch: ThunkDispatch<ISearchState, undefined, ISearchByTextAction>) => {
      const requestOptions = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + access_token
          }
      };
        const res = await fetch(
          process.env.REACT_APP_BASE_URL + '/api/PodcastSearch/SearchByText?searchText=' + text,
          requestOptions
        );
        const podcasts = await res.json();
    
        return dispatch({
          type: SearchTypes.SEARCH_BY_TEXT,
          data: podcasts
        });
      };
}