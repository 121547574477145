import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { AppState } from "reducers";
import React, { Component } from "react";
import { Modal, Button } from 'react-bootstrap';
import { hidePopup } from 'actions/app.actions';

class EpisodeInfoModal extends React.Component<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>, {}> {

    handleClose() {
        this.props.hidePopup();
    }

    render() {
        
        if (this.props.selectedEpisode === null)
            return (<div></div>);

        return (
            <Modal size='lg'
                onHide={() => { this.handleClose() }}
                show={this.props.selectedEpisode !== null}>
                <Modal.Header closeButton>
                    <label className="modal-title pull-right modal-info-title modal-podcast-title">{this.props.selectedEpisode.title.replace("&amp;", "&")}</label>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: this.props.selectedEpisode.description }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => { this.handleClose() }}>Close</Button>
                </Modal.Footer>
            </Modal >
        );
    }
}


const mapStateToProps = (state: AppState) => ({
    selectedEpisode: state.app.selectedEpisode
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            hidePopup
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EpisodeInfoModal);