import IGenre from "models/IGenre";
import { ISearchByGenresAction, ISearchByTextAction, IShowSearchingAction } from "actions/search.actions";
import { SearchTypes } from "actions/types";
import { IPodcast } from "models/IPodcast";


export interface ISearchState {
    podcasts: IPodcast[];
    podcastsLoaded: boolean;
}

const initialState: ISearchState = {
    podcasts: [],
    podcastsLoaded: false,
};

type TReducerActions = ISearchByGenresAction | ISearchByTextAction | IShowSearchingAction;

export default function (state: ISearchState = initialState, action: TReducerActions) {
    switch (action.type) {
        case SearchTypes.SHOW_SEARCH:
            return { ...state, podcastsLoaded: false };
        case SearchTypes.SEARCH_BY_TEXT:
        case SearchTypes.SEARCH_BY_GENRE:
            return { ...state, podcasts: action.data, podcastsLoaded: true };
        default:
            return state;
    }
}