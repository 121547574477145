import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { AppState } from "reducers";
import React, { Component } from "react";
import { UserSettings } from "models/UserSettings";
import { saveSpeedSetting, getUserSettings } from 'actions/app.actions';
import './index.css'

declare var window: any;

class PlayerView extends Component<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>, {}> {
    state = { itemToPlay: { url: '' }, speedDropdown: 1 }
    componentDidMount() {
        window.playerSetup.init();

        if (this.props.userId !== null && this.props.userId !== undefined) {
            this.props.getUserSettings(this.props.userId, this.props.access_token);

            const playerSetupCheck = () => {
                setTimeout(() => {
                    let player = window.playerSetup.players[0];

                    if (player === null || player === undefined) {
                        console.log("trying to set up player again")
                        window.playerSetup.init();
                        playerSetupCheck();
                    }
                }, 500);
            }
            playerSetupCheck();
        }
    }

    componentWillUpdate() {
        let speedDropdown = this.props.speedSetting !== null ? this.props.speedSetting : "1";
        if(speedDropdown != this.state.speedDropdown)
            this.setState({ speedDropdown: speedDropdown });

    }

    speedSettingChange(evt: any) {

        if (this.props.userId !== null) {
            let setting = evt.target.value;
            this.props.saveSpeedSetting(UserSettings.SpeedSetting, setting, this.props.userId, this.props.access_token);
            this.setState({ speedDropdown: setting });
        }
    }

    componentDidUpdate() {
        if (this.state.itemToPlay !== null) {
            let player = window.playerSetup.players[0];
            if (player !== null && player !== undefined) {

                if (window.urlPlaying === null || window.urlPlaying !== this.state.itemToPlay.url) {
                    //Try find the item in the drawer and play that
                    let playlistItems = player.playlistController.data.playlist;
                    let foundItem: any = null;
                    if (playlistItems !== null && playlistItems.length > 0) {
                        for (let i = 0; i < playlistItems.length; i++) {
                            let li = playlistItems[i];
                            if (li.id === this.state.itemToPlay.url) {
                                foundItem = li;
                            }

                        }
                    }
                    if (foundItem !== null) {
                        player.actions.externalPlayLink(foundItem.children[0]);
                    }
                } else {
                    player.actions.externalPause();
                }
            }
        }
    }

    render() {
        var speedItems = ["1", "1.2", "1.3", "1.4", "1.5", "1.7", "2"];

        //only display on timeline and search
        if (window.location.href.indexOf('timeline') > -1 ||
            window.location.href.indexOf('search') > -1) {
            return (
                <div className="sm2-bar-ui full-width fixed">
                    <div className="bd sm2-main-controls">
                        <div className="sm2-inline-texture"></div>
                        <div className="sm2-inline-gradient"></div>
                        <div className="sm2-inline-element sm2-button-element">
                            <div className="sm2-button-bd">
                                <a href="#play" id="play-pause" className="sm2-inline-button play-pause">Play / pause</a>
                            </div>
                        </div>
                        <div className="sm2-inline-element sm2-inline-status">
                            <div className="sm2-playlist">
                                <div className="sm2-playlist-target">
                                    <noscript><p>JavaScript is required.</p></noscript>
                                </div>
                            </div>
                            <div className="sm2-progress">
                                <div className="sm2-row">
                                    <div className="sm2-inline-time">0:00</div>
                                    <div className="sm2-progress-bd">
                                        <div className="sm2-progress-track">
                                            <div className="sm2-progress-bar"></div>
                                            <div className="sm2-progress-ball"><div className="icon-overlay"></div></div>
                                        </div>
                                    </div>
                                    <div className="sm2-inline-duration">0:00</div>
                                </div>
                            </div>
                        </div>
                        <div className="sm2-inline-element sm2-button-element sm2-volume">
                            <div className="sm2-button-bd">
                                <span className="sm2-inline-button sm2-volume-control volume-shade"></span>
                                <a href="#volume" className="sm2-inline-button sm2-volume-control">volume</a>
                            </div>
                        </div>

                        <div className="sm2-inline-element sm2-button-element">
                            <div className="sm2-button-bd">
                                <a href="#prev" title="Previous" className="sm2-inline-button previous">&lt; previous</a>
                            </div>
                        </div>
                        <div className="sm2-inline-element sm2-button-element">
                            <div className="sm2-button-bd">
                                <a href="#next" title="Next" className="sm2-inline-button next">&gt; next</a>
                            </div>
                        </div>
                        <div className="sm2-inline-element sm2-button-element">
                            <select id="setPlaybackRate" value={this.state.speedDropdown} onChange={this.speedSettingChange.bind(this)} className="custom-select">{
                                speedItems.map(function (x: any) {
                                    return (<option key={x} id={x}>{x}</option>);
                                })
                            }
                            </select>
                        </div>
                    </div>
                    <div className="bd sm2-playlist-drawer sm2-element">
                        <div className="sm2-inline-texture">
                            <div className="sm2-box-shadow"></div>
                        </div>
                        {this.renderPlaylist()}
                    </div>
                </div>
            );
        }
        return (<div></div>)
    }

    renderPlayListItems() {
        return this.props.timeLine.episodes.map(function (podcast: any) {
            return podcast.remainingItems.map((item: any) => {
                if (item === null) {
                    return (<li></li>);
                } else {
                    return (
                        <li key={item.url} id={item.url}><a href={item.url}>{item.title}</a></li>
                    );
                }
            });
        });
    }

    renderPlaylist() {
        if (this.props.timeLine !== null && this.props.timeLine.episodes.length > 0) {
            return (
                <div className="sm2-playlist-wrapper">
                    <ul className="sm2-playlist-bd">
                        {this.renderPlayListItems()}
                    </ul>
                </div>
            );
        } else {
            return (
                <div className="sm2-playlist-wrapper" >
                    <ul className="sm2-playlist-bd">
                    </ul>
                </div>
            );
        }
    }
}

const mapStateToProps = (state: AppState) => ({
    userId: state.app.userId,
    speedSetting: state.app.SpeedSetting,
    timeLine: state.subscription.timeline,
    access_token: state.app.access_token
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            saveSpeedSetting,
            getUserSettings
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayerView);