import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { AppState } from "reducers";
import React, { Component } from "react";
import { Modal, Button } from 'react-bootstrap';
import { hidePopup } from 'actions/app.actions';
import './index.css';

class PodcastInfoModal extends Component<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>, {}> {

    state = { selectedEpisode: null, selectedEpisodeGuid: null }

    addEpisode(episode: any, podcast: any) {
        // var subscriptionService = window.SubscriptionService;
        // subscriptionService.addEpisode(episode, podcast).done(function () {
        //     toastr.success("Episode Added");
        // });
    }

    playEpisode(episode: any, podcast: any) {
        // try {
        //     var player = window.playerSetup.players[0];
        //     if (player != null) {
        //         if (episode != null) {
        //             player.actions.externalPlayLink({ href: episode.url, parentNode: { offsetTop: 0, innerHTML: episode.description, getElementsByTagName(a) { return [] } } });
        //         }
        //     }
        // } catch (e) {
        //     toastr.error("Failed to play podcast");
        // }
    }

    convertDate(inputFormat: any) {
        function pad(s: any) { return (s < 10) ? '0' + s : s; }

        var d = new Date(inputFormat);
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
    }

    expandInfo(podcastItem: any) {
        let ep: any = this.state.selectedEpisode;
        if (ep === null || (ep !== null && ep.id !== podcastItem.id))
            this.setState({ selectedEpisode: podcastItem });
        else
            this.setState({ selectedEpisode: null });
    }

    renderRows() {
        /*
            Removed ability to play for now
            <div className='col-1'>
                <i className="pointer modal-button fa fa-plus-circle" onClick={this.addEpisode.bind(this, item, this.props.selectedPodcast)} aria-hidden="true" title="Add">&nbsp;</i>
            </div>
            <div className='col-1'>
                <i className="pointer modal-button fa fa-play-circle" onClick={this.playEpisode.bind(this, item)} aria-hidden="true" title="Add">&nbsp;</i>
            </div>
        */
        return this.props.selectedPodcast.episodes.map((item: any) => {
            let date = new Date(item.pubDate);
            let formattedDate = this.convertDate(date);
            let title = item.title.replace("&amp;", "&");
            if (title.length >= 50)
                title = title.substr(0, 50) + "...";

            let cssClassTitle = item === this.state.selectedEpisode ? 'col-8 hide' : 'col-8';
            let cssClassDescription = item === this.state.selectedEpisode ? 'col-8' : 'col-8 hide';

            return (
                <div className='row'>
                    <div className='col-2'>{formattedDate}</div>
                    <div className={cssClassTitle} id={item.uniqueIdentifier + "-title"}>
                        <div>
                            <span>{title}</span><a onClick={this.expandInfo.bind(this, item)} className="readMore" href="#"> read more &#187;</a>
                        </div>
                    </div>
                    <div className={cssClassDescription} id={item.uniqueIdentifier + "-Description"}><div dangerouslySetInnerHTML={{ __html: item.description }} /><a onClick={this.expandInfo.bind(this, item)} className="readMore" href="#"> &#171; read less</a></div>
                </div>
            )

        });
    }

    handleClose() {
        this.props.hidePopup();
    }

    render() {

        if (this.props.selectedPodcast === null)
            return (<div></div>);

        var description = this.props.selectedPodcast.description;
        if (description == null) description = "";

        //Removed ability to play
        //<div className='col-2'></div>
        return (
            <Modal size='lg'
                onHide={() => { this.handleClose() }}
                show={this.props.selectedPodcast !== null}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-2'>
                                <img src={this.props.selectedPodcast.imageUrl} />

                            </div>
                            <div className='col-10'>
                                <label className="modal-title pull-right modal-info-title modal-podcast-title">{this.props.selectedPodcast.name.replace("&amp;", "&")}</label>
                                <span className="modal-description" dangerouslySetInnerHTML={{ __html: description.replace("&amp;", "&") }}></span>
                                <div className="container">
                                    <div className='row'>
                                        <div className='modal-table-head col-2'>Date</div>
                                        <div className='modal-table-head col-8'>Title</div>
                                    </div>
                                    <div className='row'>
                                        <div className='container modal-table'>
                                            {this.renderRows()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => { this.handleClose() }}>Close</Button>
                </Modal.Footer>
            </Modal >
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    selectedPodcast: state.app.selectedPodcast
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            hidePopup
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PodcastInfoModal);