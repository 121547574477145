import { Component } from "react";
import { Dispatch, bindActionCreators, AnyAction } from "redux";
import { AppState } from "reducers";
import { fetchSubscribe, fetchEpisodes, playEvent, markAsPlayed } from 'actions/subscription.actions';
import React from "react";
import { connect } from "react-redux";

declare var window: any;

class External extends Component<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>, {}> {

    state = { existing: [], numCalls: 0 }

    componentDidUpdate() {
        let podcasts = this.props.podcasts;
        if (podcasts != null && podcasts.length > 0) {

            let allPodcastList: any[] = [];
            this.props.podcasts.forEach((p: any) => {
                allPodcastList.push(p);

                let podcast: any = this.state.existing.find((x: any) => {
                    return p.collectionId === x.collectionId;
                });

                if (podcast !== null && podcast !== undefined) {
                    this.props.fetchEpisodes(podcast.collectionId, this.props.userId, this.props.access_token);
                }
            });

            if (this.state.existing.length !== allPodcastList.length)
                this.setState({ existing: allPodcastList });
        }

        if (podcasts.length === 0 && this.props.userId !== null && this.state.numCalls < 5) {
            this.props.fetchSubscribe(this.props.userId, this.props.access_token);
            this.setState({ numCalls: this.state.numCalls + 1 });
        }
    }

    componentDidMount() {

        window.baseUrl = process.env.REACT_APP_URL;

        let podcasts = this.props.podcasts;
        if (podcasts != null && podcasts.length > 0) {
            podcasts.forEach((podcast: any) => {
                this.props.fetchEpisodes(podcast.collectionId, this.props.userId, this.props.access_token);
            });

            this.setState({ existing: podcasts });
        }

        this.props.fetchSubscribe(this.props.userId, this.props.access_token);

        let debounce = this.debounce(() => {
            console.log('TrackPlaying');
        }, 1000, false);

        document.addEventListener("TrackPlaying", debounce);

        document.addEventListener("PlayEvent", (e: any) => {
            this.props.playEvent(e.detail.isPlaying, e.detail.urlPlaying);
        });

        document.addEventListener("TrackFinished", (e: any) => {
            let foundEp: any = null, foundSub: any = null;
            this.props.podcasts.forEach((element: any) => {
                element.episodes.forEach((x: any) => {
                    if (x.url === e.detail.trackInfo.url) {
                        foundEp = x;
                        foundSub = element;
                    }
                });
            });
            this.props.markAsPlayed(this.props.userId, this.props.access_token, foundSub.id, foundEp.id);
        });
    }

    debounce(func: any, wait: any, immediate: any) {
        let timeout: any;
        let context = this;
        return function () {
            let args: any = arguments;
            let later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    render() {
        return (<div></div>);
    }
}

const mapStateToProps = (state: AppState) => ({
    userId: state.app.userId,
    access_token: state.app.access_token,
    podcasts: state.subscription.subscriptions
});


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            fetchSubscribe,
            fetchEpisodes,
            playEvent,
            markAsPlayed
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(External);