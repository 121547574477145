import { combineReducers } from "redux";
import lookupReducer from './lookups.reducer';
import searchReducer from './search.reducer';
import appReducer from './app.reducer';
import subscriptionReducer from './subscription.reducer';

const rootReducer = combineReducers({
    lookups: lookupReducer,
    search: searchReducer,
    app: appReducer,
    subscription: subscriptionReducer
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;