import React, { Component } from "react"
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { AppState } from "reducers";
import { connect } from "react-redux";
import logo from './icon-mike2-80-85.png';
import './index.css';

declare var Oidc: any;

class home extends Component<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>, {}> {

    componentDidMount() {
        console.log(process.env);
    }

    login() {

        var config = {
            authority: process.env.REACT_APP_IDENTITY_URL,
            client_id: "spaclient",
            redirect_uri: process.env.REACT_APP_URL + "/timeline",
            response_type: "code",
            scope: "openid profile digicatcher.api",
            post_logout_redirect_uri: process.env.REACT_APP_URL,
        };
        let userManager = new Oidc.UserManager(config);
        userManager.signinRedirect();
    }

    public render() {
        return (
            <div className='home'>
                <span className='home'>
                    <div className="logoArea pull-left">
                        <img alt={logo} src={logo} />
                        <h1>DigiCatcher</h1>
                        <p className="sub_Text">Podcast Player</p>
                    </div>
                    <div className="pull-right main-menu">
                        <a className="listen pointer" onClick={this.login.bind(this)}>
                            <span className="fa fa-volume-off volume"></span>&nbsp;
                        <span className="volume-text">Listen</span>
                        </a>
                    </div>
                </span>
                <div className="caption">
                    <div className="heading">250,000 unique podcasts</div>
                    <h4 className="heading2">in more than 100 languages</h4>
                </div>
            </div>);
    }
}

const mapStateToProps = (state: AppState) => ({

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(home);