import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../icon-mike2-80-85.png';
import './index.css';

export default () => {

    let timelineCss = window.location.href.indexOf('timeline') > 0 ? "userButtonLink active-headerButton" : "userButtonLink headerButton";
    let searchCss = window.location.href.indexOf('search') > 0 ? "userButtonLink active-headerButton" : "userButtonLink headerButton";

    return (
        <div className="toplinkArea">
            <div className="logoArea-small pull-left">
                <img alt={logo} src={logo} />
            </div>
            <div className="pull-right main-menu">
                <div className="row">
                    <Link className={timelineCss} to="/timeline">
                        Timeline
                        </Link>
                    <Link className={searchCss} to="/search">
                        Search
                        </Link>
                    <button className="userButtonLink headerButton">
                        Log off
                        </button>
                </div>
            </div>
        </div>
    );

}