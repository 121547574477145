import React, { Component } from "react";
import { AppState } from "reducers";
import { bindActionCreators, Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { showPodcastPopup, showLoading } from 'actions/app.actions';
import { subscribe } from 'actions/subscription.actions';

declare var toastr: any;
class ResultFrame extends Component<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>, {}> {

    state = { selectedPodcastId: 0 }

    podcastImgClick(item: any) {
        this.props.showLoading();
        this.setState({ selectedPodcastId: item.id });
        this.props.showPodcastPopup(item.id, this.props.access_token);
    }

    recommendClickHandler(item: any) { }

    playClickHandler(item: any) { }

    subscribeClickHandler(item: any) {
        this.props.subscribe(item, this.props.userId, this.props.access_token);
        let existing = this.props.subscriptions.filter((x: any) => x.collectionId === item.collectionId);
        let message = existing.length > 0 ? "Unsubscribed from " + item.name : "Subscribed to" + item.name;
        toastr.success(message);
    }

    renderItem(row: any) {
        let _this = this;
        return row.map((item: any) => {
            let recId = "recommend" + item.collectionId;
            let addId = "add" + item.collectionId;
            let existing = this.props.subscriptions.filter((x: any) => x.collectionId === item.collectionId);
            let icon = existing.length > 0
                ? "fa fa-minus-circle pointer"
                : "fa fa-plus-circle pointer";
            let cssClass = this.state.selectedPodcastId === item.id && !this.props.loaded
                ? "cssload"
                : "cssload hide";

            /*            
                <span className="buttonSpacer">
                    <i id={playId} className="fa fa-play-circle pointer" onClick={_this.playClickHandler.bind(_this, item)} aria-hidden="true" title="Play Podcast"></i>
                </span>
             */

            return (
                <div key={"PodcastRow" + item.collectionId} className="col-lg-3">
                    <div id={item.collectionId} className="podcast">
                        <h6>{item.name}</h6>
                        <div id={"img-loading-" + item.collectionId} className={cssClass}>
                            <div className="spinner">
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                            </div>
                        </div>
                        <img id={"podImage-" + item.collectionId} alt={item.imageUrl} src={item.imageUrl} className="pointer" onClick={_this.podcastImgClick.bind(this, item)} />

                        <div>
                            <span className="buttonSpacer">
                                <i id={recId} className="fa fa-heart-o pointer" onClick={_this.recommendClickHandler.bind(_this, item)} aria-hidden="true" title="Recommend"></i>
                            </span>
                            <span className="buttonSpacer">
                                <i id={addId} className={icon} onClick={_this.subscribeClickHandler.bind(_this, item)} aria-hidden="true" title="Subscribe"></i>
                            </span>
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderRow() {
        return this.props.items.map((row: any) => {
            return (
                <li key={row[0].collectionId} className="list-group-item borderless">
                    <div className="row">
                        {this.renderItem(row)}
                    </div>
                </li>
            )
        });
    }

    render() {

        if (!this.props.podcastsLoaded) {
            return (<div className='centered'><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>)
        }
        
        if (this.props.items.length > 0) {
            return (
                <div className='container' id="podcastList">
                    <div className="row">
                        <ul className='list-group borderless'>
                            {this.renderRow()}
                        </ul>
                    </div>
                </div>
            );
        }

        return (
            <div className="alert alert-primary" role="alert">
                No Podcasts found please try another search phrase.
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    items: state.search.podcasts,
    subscriptions: state.subscription.subscriptions,
    loaded: state.app.selectedPodcastLoaded,
    podcastsLoaded: state.search.podcastsLoaded,
    access_token: state.app.access_token,
    userId: state.app.userId
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            showPodcastPopup,
            showLoading,
            subscribe
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultFrame);