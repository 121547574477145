export enum LookupTypes {
    GET_GENRES = 'get_genres'
};

export enum SearchTypes {
    SEARCH_BY_GENRE = 'search_by_genre',
    SEARCH_BY_TEXT = 'search_by_text',
    SHOW_SEARCH = 'show_search'
};

export enum AppTypes {
    SHOW_PODCAST_POPUP = 'show_podcast_popup',
    SHOW_EPISODE_POPUP = 'show_episode_popup',
    HIDE_POPUP = 'hide_popup',
    SHOW_LOADING = 'show_loading',
    SAVE_SETTING = 'save_Setting',
    GET_SETTING = 'get_Setting',
    GET_USER = 'get_user',
    GET_USER_ID = 'get_user_id'
}

export enum SubscriptionTypes{
    SUBSCRIBE = 'subscribe',
    RECOMMEND = 'recommend',
    FETCH_SUBSCRIPTIONS = 'fetch_subscriptions',
    FETCH_EPISODES = 'fetch_episodes',
    FETCH_TIMELINE = 'fetch_timeline',
    MARK_AS_PLAYED = 'mark_as_played',
    TRACK_PLAYING = 'track_playing',
    PLAY_EVENT = 'play_event'
}