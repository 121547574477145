import { IShowPodcastPopupAction, IShowEpisodePopupAction, IHidePopupAction, IShowLoadingAction, IUserSettingAction, IGetUserSettingAction, IGetUserAction, IGetUserIdAction } from "actions/app.actions";
import { AppTypes } from "actions/types";
import { UserSettings } from "models/UserSettings";
import { userInfo } from "os";

export interface IAppState {
    selectedEpisode: any;
    selectedPodcast: any;
    selectedPodcastLoaded: boolean;
    SpeedSetting: number;
    userId: any;
    access_token: any;
    email: any;
    name: any;
}

const initialState: IAppState = {
    selectedEpisode: null,
    selectedPodcast: null,
    selectedPodcastLoaded: true,
    SpeedSetting: 1 ,
    userId: null,
    access_token: null,
    email: null,
    name: null
};

type TReducerActions = IShowPodcastPopupAction | IShowEpisodePopupAction | IHidePopupAction |
    IShowLoadingAction | IUserSettingAction | IGetUserSettingAction | IGetUserAction |
    IGetUserIdAction;

export default function (state: IAppState = initialState, action: TReducerActions) {
    switch (action.type) {
        case AppTypes.GET_USER_ID: {
            return { ...state, userId: action.data };
        }
        case AppTypes.GET_USER:
            if (action.data !== null) {
                return { ...state, access_token: action.data.access_token, name: action.data.profile.name, email: action.data.profile.email };
            }
            return state;
        case AppTypes.GET_SETTING:
            return { ...state, userSettings: action.data };
        case AppTypes.SAVE_SETTING:
            return { ...state };
        case AppTypes.SHOW_LOADING:
            return { ...state, selectedPodcastLoaded: false };
        case AppTypes.HIDE_POPUP:
            return { ...state, selectedEpisode: null, selectedPodcast: null, selectedPodcastLoaded: true };
        case AppTypes.SHOW_PODCAST_POPUP:
            return { ...state, selectedPodcast: action.data, selectedPodcastLoaded: true };
        case AppTypes.SHOW_EPISODE_POPUP:
            return { ...state, selectedEpisode: action.data };
        default:
            return state;
    }
}