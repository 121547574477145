import React from "react"
import Header from "components/Shared/Header";
import { AppState } from "reducers";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchTimeline, markAsPlayed, fetchEpisodes } from 'actions/subscription.actions';
import { showEpisodePopup, getUser, getUserId } from 'actions/app.actions';
import EpisodeInfoModal from "components/Shared/EpisodeInfoModal";
import './index.css';

declare var window: any;
declare var Oidc: any;

class TimeLine extends React.Component<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>, {}> {
    state = { showFinshed: false, from: 0, to: 10 }

    componentDidMount() {
        if (this.props.access_token === null)

        new Oidc.UserManager({
            authority: process.env.REACT_APP_IDENTITY_URL,
            client_id: "spaclient",
            redirect_uri: process.env.REACT_APP_URL + "/timeline",
            response_type: "code",
            response_mode: "query",
            scope: "openid profile digicatcher.api",
            post_logout_redirect_uri: process.env.REACT_APP_URL,
        }).signinRedirectCallback().then(() => {
            this.props.getUser();
        });

        if (this.props.userId > 0 && !this.props.timelineLoaded) {
            this.props.fetchTimeline(this.props.userId, this.props.access_token, this.state.showFinshed, this.state.from, this.state.to);
        }
    }

    componentDidUpdate() {
        if (this.props.userId === null)
            this.props.getUserId(this.props.access_token, this.props.email, this.props.name);

        if (this.props.userId > 0 && !this.props.timelineLoaded) {
            this.props.fetchTimeline(this.props.userId, this.props.access_token, this.state.showFinshed, this.state.from, this.state.to);
        }

        if (this.props.markAsTrackPlayed)
            this.props.fetchTimeline(this.props.userId, this.props.access_token, this.state.showFinshed, this.state.from, this.state.to);
    }

    playClickHandler(item: any) {
        let player = window.playerSetup.players[0];
        if (player !== null) {

            if (window.urlPlaying === null || window.urlPlaying !== item.url) {
                player.actions.play(item.index);
            } else {
                player.actions.externalPause();
            }
        }
        else {
            console.log("Player is empty, cant play");
        }
    }

    showPodcastEpisodeInfo(item: any) {
        this.props.showEpisodePopup(item);
    }

    markItemPlayed(item: any) {
        this.props.markAsPlayed(item.userId, this.props.access_token, item.podcastId, item.id);
    }

    showFinishedClick(e: any) {
        this.setState({ showFinshed: !this.state.showFinshed });
        this.props.fetchTimeline(this.props.userId, this.props.access_token, !this.state.showFinshed, this.state.from, this.state.to);
    }

    handleOnScroll(e: any) {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.setState({ to: (this.state.to + 10) });
            this.props.fetchTimeline(this.props.userId, this.props.access_token, this.state.showFinshed, this.state.from, this.state.to);
        }
    }

    reload() {
        let podcasts = this.props.podcasts;

        if (podcasts !== null && podcasts.length > 0) {
            podcasts.forEach((podcast: any) => {
                this.props.fetchEpisodes(podcast.collectionId, this.props.userId, this.props.access_token);
            });
        }
    }

    renderDate(dateItem: any) {
        return dateItem.remainingItems.map((item: any) => {
            var playPauseCss = item.url === this.props.urlPlaying && this.props.isPlaying ? "fa fa-pause-circle playingPodcastItem pointer" : "fa fa-play-circle pointer";
            var playPauseCssText = item.url === this.props.urlPlaying && this.props.isPlaying ? "title timelineItem playingPodcastItem" : "title timelineItem";

            if (item.isFinished) {

                return (
                    <div className="row">
                        <div className="col-3 timelineLeftSpacer">
                            <span className="timelineButtonSpacer">
                                <i className={playPauseCss}
                                    onClick={this.playClickHandler.bind(this, item)}
                                    aria-hidden="true"
                                    title="Play Podcast"></i>
                            </span>
                            <span className="timelineButtonSpacer">
                                <i className="fa fa-info-circle pointer"
                                    onClick={this.showPodcastEpisodeInfo.bind(this, item)}
                                    aria-hidden="true"
                                    title="Play Podcast"></i>
                            </span>
                            <span className="timelineButtonSpacer">
                                <i className="fa fa-check-circle finished"
                                    aria-hidden="true"
                                    title="Finished"></i>
                            </span>
                        </div>
                        <div className="col-8 timelineRightSpacer">
                            <img className="imgDisplay" src={item.imageUrl} />
                            <span className={playPauseCssText}>{item.title.replace("&amp;", "&")}</span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="row">
                        <div className="col-3 timelineLeftSpacer">
                            <span className="timelineButtonSpacer">
                                <i className={playPauseCss}
                                    onClick={this.playClickHandler.bind(this, item)}
                                    aria-hidden="true"
                                    title="Play Podcast"></i>
                            </span>
                            <span className="timelineButtonSpacer">
                                <i className="fa fa-minus-circle pointer"
                                    onClick={this.markItemPlayed.bind(this, item)}
                                    aria-hidden="true"
                                    title="Mark as played"></i>
                            </span>
                            <span className="timelineButtonSpacer">
                                <i className="fa fa-info-circle pointer"
                                    onClick={this.showPodcastEpisodeInfo.bind(this, item)}
                                    aria-hidden="true"
                                    title="Play Podcast"></i>
                            </span>
                        </div>
                        <div className="col-8 timelineRightSpacer">
                            <img className="imgDisplay" src={item.imageUrl} />
                            <span className={playPauseCssText}>{item.title.replace("&amp;", "&")}</span>
                        </div>
                    </div>
                );
            }
        })
    }

    renderTimeLine() {
        return this.props.timeLine.episodes.map((item: any) => {
            return (
                <li key={item.pubDateString}>
                    <span></span>
                    {this.renderDate(item)}
                    <span className="number"><span>{item.pubDateString}</span><span></span></span>
                </li>
            );
        })
    }

    render() {
        /*
        
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-2">
                            <i className="fa fa-sort-amount-asc pointer mainButtons" title="Sort by date"></i>
                        </div>
                        <div className="col-1">
                            <i className="fa fa-repeat pointer mainButtons" title="Reload" onClick={this.reload.bind(this)}></i>
                        </div>
                    </div>
         */


        if (!this.props.timelineLoaded) {
            return (
                <div className='fullWindow'>
                    <Header />
                    <EpisodeInfoModal />
                    <div className="container timeline">
                        <div className='centered'><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
                    </div>
                </div>
            );
        }

        return (
            <div className='fullWindow'>
                <Header />
                <EpisodeInfoModal />
                <div className="container timeline">
                    <ul className='timeline-list' onScroll={this.handleOnScroll.bind(this)}>
                        {this.renderTimeLine()}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    timeLine: state.subscription.timeline,
    markAsTrackPlayed: state.subscription.markAsTrackPlayed,
    isPlaying: state.subscription.isPlaying,
    podcasts: state.subscription.subscriptions,
    urlPlaying: state.subscription.url,
    userId: state.app.userId,
    email: state.app.email,
    name: state.app.name,
    access_token: state.app.access_token,
    timelineLoaded: state.subscription.timelineLoaded
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            fetchTimeline,
            showEpisodePopup,
            markAsPlayed,
            fetchEpisodes,
            getUser,
            getUserId
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeLine);