import React from "react"
import Header from "components/Shared/Header";
import SearchFrame from "./SearchFrame";
import ResultFrame from "./ResultFrame";
import PodcastInfoModal from "components/Shared/PodcastInfoModal";
import './index.css';

class Search extends React.Component {
    render() {
        return (
            <div className='fullWindow'>
                <Header />
                <div id="podcastSearch">
                    <SearchFrame />
                    <ResultFrame />
                    <PodcastInfoModal />
                </div>
            </div>
        );
    }
}

export default Search;