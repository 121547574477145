import { AppTypes } from './types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IAppState } from 'reducers/app.reducer';

declare var Oidc: any;

export interface IShowPodcastPopupAction {
    type: AppTypes.SHOW_PODCAST_POPUP,
    data: any
}

export interface IShowEpisodePopupAction {
    type: AppTypes.SHOW_EPISODE_POPUP,
    data: any
}

export interface IHidePopupAction {
    type: AppTypes.HIDE_POPUP,
    data: any
}

export interface IShowLoadingAction {
    type: AppTypes.SHOW_LOADING,
    data: any
}

export interface IUserSettingAction {
    type: AppTypes.SAVE_SETTING
}

export interface IGetUserSettingAction {
    type: AppTypes.GET_SETTING,
    data: any
}

export interface IGetUserAction {
    type: AppTypes.GET_USER,
    data: any
}

export interface IGetUserIdAction {
    type: AppTypes.GET_USER_ID,
    data: any
}

export function getUser(): ThunkAction<
    Promise<IGetUserAction>,
    IAppState,
    undefined,
    IGetUserAction> {
    return async (dispatch: ThunkDispatch<IAppState, undefined, IGetUserAction>) => {

        var config = {
            authority: process.env.REACT_APP_IDENTITY_URL,
            client_id: "spaclient",
            redirect_uri: process.env.REACT_APP_URL + "/timeline",
            response_type: "code",
            scope: "openid profile digicatcher.api",
            post_logout_redirect_uri: process.env.REACT_APP_URL,
        };
        var mgr = new Oidc.UserManager(config);

        mgr.getUser().then((user: any) => {
            if (user) {
                return dispatch({
                    type: AppTypes.GET_USER,
                    data: user
                });
            }
        });

        return dispatch({
            type: AppTypes.GET_USER,
            data: null
        });
    };
}

export function showPodcastPopup(id: number, access_token: any): ThunkAction<
    Promise<IShowPodcastPopupAction>,
    IAppState,
    undefined,
    IShowPodcastPopupAction> {
    return async (dispatch: ThunkDispatch<IAppState, undefined, IShowPodcastPopupAction>) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            }
        };
        const res = await fetch(
            process.env.REACT_APP_BASE_URL + '/api/LoadFeed?collectionId=' + id,
            requestOptions
        );
        const podcast = await res.json();

        return dispatch({
            type: AppTypes.SHOW_PODCAST_POPUP,
            data: podcast
        });
    };
}

export function showEpisodePopup(podcast: any): IShowEpisodePopupAction {
    return {
        type: AppTypes.SHOW_EPISODE_POPUP,
        data: podcast
    };
}

export function saveSpeedSetting(settingId: number, value: any, userId: any, access_token: any): ThunkAction<
    Promise<IUserSettingAction>,
    IAppState,
    undefined,
    IUserSettingAction> {
    return async (dispatch: ThunkDispatch<IAppState, undefined, IUserSettingAction>) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            }
        };

        const res = await fetch(process.env.REACT_APP_BASE_URL + '/api/UserSetting?userId=' + userId + '&settingId=' + settingId + "&value=" + value, requestOptions);

        return dispatch({
            type: AppTypes.SAVE_SETTING
        });
    };
}

export function showLoading(): IShowLoadingAction {
    return {
        type: AppTypes.SHOW_LOADING,
        data: true
    };
}

export function hidePopup(): IHidePopupAction {
    return {
        type: AppTypes.HIDE_POPUP,
        data: true
    };
}

export function getUserSettings(userId: any, access_token: any): ThunkAction<
    Promise<IGetUserSettingAction>,
    IAppState,
    undefined,
    IGetUserSettingAction> {
    return async (dispatch: ThunkDispatch<IAppState, undefined, IGetUserSettingAction>) => {

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            }
        };
        const res = await fetch(
            process.env.REACT_APP_BASE_URL + '/api/usersetting?userId=' + userId,
            requestOptions
        );
        const podcast = await res.json();

        return dispatch({
            type: AppTypes.GET_SETTING,
            data: podcast
        });
    };
}

export function getUserId(access_token: any, email: any, name: any): ThunkAction<
    Promise<IGetUserIdAction>,
    IAppState,
    undefined,
    IGetUserIdAction> {
    return async (dispatch: ThunkDispatch<IAppState, undefined, IGetUserIdAction>) => {

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            }
        };
        const res = await fetch(process.env.REACT_APP_BASE_URL + '/api/user?email=' + email + "&name=" + name, requestOptions);
        const userId = await res.json();

        return dispatch({
            type: AppTypes.GET_USER_ID,
            data: userId
        });
    };
}