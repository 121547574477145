import { ISubscribeAction, IFetchSubscribeAction, IFetchEpisodesAction, IFetchTimelineAction, IMarkAsPlayedAction, ITrackPlayingAction, IPlayEventAction } from "actions/subscription.actions";
import { SubscriptionTypes } from "actions/types";

export interface ISubscriptionState {
    markAsTrackPlayed: boolean;
    subscriptions: any[];
    timeline: any;
    isPlaying: any;
    url: any;
    timelineLoaded: boolean;
}

const initialState: ISubscriptionState = {
    markAsTrackPlayed: false,
    subscriptions: [],
    timeline: { episodes: [] },
    isPlaying: false,
    url: '',
    timelineLoaded: false
};

type TReducerActions = ISubscribeAction | IFetchSubscribeAction | IFetchEpisodesAction | IFetchTimelineAction | IMarkAsPlayedAction | ITrackPlayingAction | IPlayEventAction;

export default function (state: ISubscriptionState = initialState, action: TReducerActions) {
    switch (action.type) {
        case SubscriptionTypes.PLAY_EVENT:
            return { ...state, isPlaying: action.data.isPlaying, url: action.data.url };
        case SubscriptionTypes.FETCH_TIMELINE:
            return { ...state, timeline: action.data, markAsTrackPlayed: false, timelineLoaded: true }
        case SubscriptionTypes.FETCH_EPISODES: {
            let subscriptions = state.subscriptions;
            let podcast = subscriptions.find((x: any) => x.collectionId === action.data.collectionId);
            podcast.episodes = action.data.episodes;
            return { ...state, subscriptions: subscriptions, markAsTrackPlayed: false, timelineLoaded: false }
        }
        case SubscriptionTypes.FETCH_SUBSCRIPTIONS:
            return { ...state, subscriptions: action.data, markAsTrackPlayed: false }
        case SubscriptionTypes.SUBSCRIBE:
            return { ...state, subscriptions: action.data.returnedItems, markAsTrackPlayed: false,timelineLoaded: false };
        case SubscriptionTypes.MARK_AS_PLAYED:
            return { ...state, markAsTrackPlayed: true };
        default:
            return state;
    }
}