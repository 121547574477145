import React, { Component } from "react";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "reducers";
import { connect } from "react-redux";
import { getGenres } from 'actions/lookups.actions'
import { searchByGenre, showSearch } from 'actions/search.actions'
import { IPodcast } from "models/IPodcast";

class GenreFrame extends Component<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>, {}> {
    public componentDidMount() {
        this.props.getGenres();
    }

    public componentDidUpdate() {
        if (this.props.genres !== null) {
            let arts = this.props.genres.find(x => x.name === 'Arts');
            this.selectChange({ target: { value: arts.id } });
        }
    }

    selectChange(e: any) {
        this.props.showSearch();
        var id = parseInt(e.target.value);
        if (isNaN(id))
            return;

        let selectedGenre: IPodcast = this.props.genres.filter((g) => g.id === id)[0];
        this.props.searchByGenre(selectedGenre.id, this.props.access_token);
    }

    render() {
        let genres = this.props.genres;
        if (genres !== null && genres.length > 0) {
            const arts = genres.find(x => x.name === 'Arts');

            return (
                <div>
                    <select id="categoryList" defaultValue={arts.id} className="form-control" onChange={this.selectChange.bind(this)} >
                        {
                            genres.map((item: any) => {
                                return (<option key={item.id} value={item.id}>{item.name}</option>);
                            })
                        }
                    </select>
                </div>
            );
        }
        return (<select id="categoryList"></select>);
    }
}

const mapStateToProps = (state: AppState) => ({
    genres: state.lookups.genres,
    access_token: state.app.access_token
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            searchByGenre,
            getGenres,
            showSearch
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenreFrame);