import React, { Component } from "react";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "reducers";
import { connect } from "react-redux";
import { searchByText, showSearch } from 'actions/search.actions'
import GenreFrame from "./GenreFrame";

declare var toastr: any;
class SearchFrame extends Component<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>, {}> {
    state = { searchText: '' };

    search() {
        if (this.state.searchText === '') {
            toastr.warning('Please enter search text');
        } else {
            this.props.showSearch();
            this.props.searchByText(this.state.searchText, this.props.access_token);
        }
    }

    handleChange(evt: any) {
        this.setState({ searchText: evt.target.value.substr(0, 100) });
    }

    clickGenre(id: any) {
        this.setState({ searchText: '' });
    }

    handleKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.search();
        }
    }

    render() {
        return (
            <div className='container'>
                <div className="row">
                    <div className="col-4">
                        <div className="form-group">
                            <input type="text" value={this.state.searchText} className="form-control input-padding input-Search" id="search" placeholder="Podcast Name" onChange={this.handleChange.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} />
                        </div>
                    </div>
                    <div className='col-3'>
                        <button onClick={this.search.bind(this)} className="btn btn-success">Search</button>
                    </div>
                    <div className="col-4">
                        <GenreFrame />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    access_token: state.app.access_token
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            searchByText,
            showSearch
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchFrame);