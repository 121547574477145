import IGenre from "models/IGenre";
import { IGetGenresAction } from "actions/lookups.actions";
import { LookupTypes } from "actions/types";


export interface ILookupState {
    genres: IGenre[];
    genresLoaded: boolean;
}

const initialState: ILookupState = {
    genres: [],
    genresLoaded: false
};

type TReducerActions = IGetGenresAction;

export default function (state: ILookupState = initialState, action: TReducerActions) {
    switch (action.type) {
        case LookupTypes.GET_GENRES:
            return { ...state, genres: action.data, genresLoaded: true };
        default:
            return state;
    }
}